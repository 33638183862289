<template>
    <div class="misc-wrapper">
  
      <div class="misc-inner p-2 p-sm-3">
        <div class="w-100 text-center">
          <h1 class="mb-1">
            Gracias por Confirmar Tu Email ! 🔐
          </h1>
          <p class="mb-2">
            Tu Cuenta ha Sido Activada Correctamente<br/>
            Ya Puedes Acceder a Todos Nuestros Servicios
          </p>
          <div  style="width: 10% !important; margin-top: 0% !important;"  >
          <vuexy-logo/>
        </div>
          <b-img
            fluid
            :src="require('@/assets/images/logo/playstore.png')"
            alt="Bienvenido a LavarClick !"
          />

          <!-- <b-button
            variant="primary"
            class="mb-1 btn-sm-block"
            :to="loginRoute()"
          >Back to Home</b-button> -->
          <b-img
            fluid
            :src="imgUrl"
            alt="Bienvenido a LavarClick !"
          />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  /* eslint-disable global-require */
  import { BLink, BImg, BButton } from 'bootstrap-vue'
  import VuexyLogo from '@core/layouts/components/Logo.vue'
  import store from '@/store/index'
  import { getHomeRouteForLoggedInUser } from '@/auth/utils'
  
  export default {
    components: {
      BLink, BImg, BButton, VuexyLogo,
    },
    data() {
      return {
        downImg: require('@/assets/images/pages/not-authorized.svg'),
      }
    },
    computed: {
      imgUrl() {
        if (store.state.appConfig.layout.skin === 'dark') {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.downImg = require('@/assets/images/pages/not-authorized-dark.svg')
          return this.downImg
        }
        return this.downImg
      },
    },
    methods: {
      loginRoute() {
        // const user = JSON.parse(localStorage.getItem('userData'))
        // return getHomeRouteForLoggedInUser(user ? user.role : null)
      },
    },
  }
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/pages/page-misc.scss';
  </style>
  